import React, { useEffect } from "react";
import ProjectDialog from "../ProjectDialog";
import "./index.css";
import {
  CARD_LABELS,
  PROJECT_STATUSES,
  PROJECT_STATUS_COLORS,
  ProjectStatus,
} from "../../helpers/labels";
import { language } from "../../helpers/plugin";

const ProjectCard = ({ name, data }: { name: string; data: any }) => {
  const [open, setOpen] = React.useState(false);

  const investmentLockupMonthsPeriod = Math.floor(
    data.duration / 60 / 60 / 24 / 30
  );

  const financingMonthsPeriod =
    new Date(data.funding_end).getMonth() -
    new Date(data.funding_start).getMonth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.documentElement.classList.add("modal-open");
    } else {
      document.documentElement.classList.remove("modal-open");
    }
  }, [open]);

  return (
    <div className={"project-card-wrapper"}>
      <div onClick={handleClickOpen} className={`project-card ${name}`}>
        <div
          className="project-card-background"
          style={
            data.image && {
              backgroundImage: `url(${data.image})`,
            }
          }
        >
          <div
            className="project-card-badge"
            style={{
              background:
                PROJECT_STATUS_COLORS[
                  data.status as keyof typeof PROJECT_STATUS_COLORS
                ],
            }}
          >
            <span>
              {
                PROJECT_STATUSES[language as keyof typeof PROJECT_STATUSES][
                  data.status as ProjectStatus
                ]
              }
            </span>
          </div>
        </div>
        <div className="project-card-content">
          <h3 className="project-card-title">{data.name[language]}</h3>
        </div>

        <div className="project-card-text">
          <div className="project-card-text-title">
            {CARD_LABELS[language].generalInfoTitle}
          </div>
          <div className="project-card-text-paragraph">
            <p>
              {CARD_LABELS[language].investmentLockup.replace(
                "{value}",
                `${investmentLockupMonthsPeriod}`
              )}
            </p>
            <p>
              {CARD_LABELS[language].fundingTarget.replace(
                "{value}",
                `${data.min_funding_target.toLocaleString()} ${data.investment_currency.toUpperCase()}`
              )}
            </p>
            <p>
              {CARD_LABELS[language].return.replace(
                "{value}",
                data.return_rate
              )}
            </p>
            <p>
              {CARD_LABELS[language].period.replace(
                "{value}",
                `${financingMonthsPeriod || 1}`
              )}
            </p>
          </div>
          <div className="project-card-link">
            <span>
              {CARD_LABELS[language].exploreLink}{" "}
              <span className="link-arrow">→</span>
            </span>
          </div>
        </div>
      </div>
      <ProjectDialog
        name={`${name}-dialog`}
        open={open}
        handleClose={handleClose}
        data={data}
      />
    </div>
  );
};

export default ProjectCard;
