import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

interface IProjectsPluginProps {
  language: string;
  items: number;
}

declare global {
  interface Window {
    projectsPluginProps: IProjectsPluginProps;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("pluginProjectsSlider") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
