import ProjectsSlider from "./components/ProjectsSlider";
import "./App.css";

function App() {
  return (
    <div className="App">
      <ProjectsSlider />
    </div>
  );
}

export default App;
