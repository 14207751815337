import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import PictureElement from "../PictureElement";
import "./index.css";
import { CARD_LABELS } from "../../helpers/labels";
import parse from "html-react-parser";
import { language } from "../../helpers/plugin";

const ProjectDialog = ({
  name,
  data,
  open,
  handleClose,
}: {
  name: string;
  data: any;
  open: boolean;
  handleClose: any;
}) => {
  const description = data.description[language]
    .replace(
      new RegExp(`${CARD_LABELS[language].generalInfoTitle}(\r\n\r\n)?`, "gi"),
      "<p class='project-dialog-subtitle'>$&</p>"
    )
    .replace(
      new RegExp(`${CARD_LABELS[language].investmentTitle}(\r\n\r\n)?`, "gi"),
      "<p class='project-dialog-subtitle'>$&</p>"
    );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      className={`project-dialog ${name}`}
    >
      <DialogActions>
        <Button
          onClick={handleClose}
          autoFocus
          className="project-dialog-close"
        >
          <img
            src="https://dist.thecoopnetwork.io/plugins/projects-slider/media/icons/close-light.svg"
            alt="close"
          />
        </Button>
      </DialogActions>
      <div className="project-dialog-image-container">
        {data.video ? (
          <video
            className="project-dialog-video"
            autoPlay={true}
            muted={true}
            playsInline={true}
            loop={true}
          >
            <source
              data-src={process.env.PUBLIC_URL + data.video}
              type="video/mp4"
              src={process.env.PUBLIC_URL + data.video}
            ></source>
          </video>
        ) : (
          data.image && (
            <PictureElement
              webp={process.env.PUBLIC_URL + data.image}
              jpg={process.env.PUBLIC_URL + data.image.replace(".webp", ".jpg")}
            />
          )
        )}
      </div>

      <DialogContent className="project-dialog-content">
        <h2 className="project-dialog-title">{data.name[language]}</h2>
        <div className="project-dialog-text">{parse(description)}</div>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDialog;
