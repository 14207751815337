export const CARD_LABELS = {
  en: {
    generalInfoTitle: "Key information",
    investmentTitle: "Investment Opportunity",
    exploreLink: "Explore",
    investmentLockup: "Investment Lockup period: {value} months",
    lockupPeriod: "Lockup method: {value}",
    fundingTarget: "Funding target: {value}",
    return: "Fixed return: {value}% APR",
    period: "Financing period: {value} months",
  },
  ro: {
    generalInfoTitle: "Informații cheie",
    investmentTitle: "Oportunitatea pentru investiții",
    exploreLink: "Explorează",
    investmentLockup: "Perioada de depozit a investiției: {value} luni",
    lockupPeriod: "Metoda de depozitare: {value}",
    fundingTarget: "Target de finanțare: {value}",
    return: "Randament: {value}% APR",
    period: "Perioada finanțării: {value} luni",
  },
} as const;

export enum ProjectStatus {
  DEPLOYED = "deployed",
  FUNDING = "funding",
  FUNDING_SUCCESSFUL = "development",
  DEVELOPMENT = "development",
  COMPLETED = "completed",
}

export const PROJECT_STATUSES = {
  en: {
    [ProjectStatus.DEPLOYED]: "soon",
    [ProjectStatus.FUNDING]: "available",
    [ProjectStatus.DEVELOPMENT]: "ongoing",
    [ProjectStatus.COMPLETED]: "finished",
  },
  ro: {
    [ProjectStatus.DEPLOYED]: "în curând",
    [ProjectStatus.FUNDING]: "disponibil",
    [ProjectStatus.DEVELOPMENT]: "în dezvoltare",
    [ProjectStatus.COMPLETED]: "finalizat",
  },
} as const;

export const PROJECT_STATUS_COLORS = {
  funding: "#5546FB",
  deployed: "#FF288B",
  development: "#1D4ED8",
  completed: "#05855C",
} as const;
