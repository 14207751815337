import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ProjectCard from "../ProjectCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { sliderItems } from "../../helpers/plugin";
import { ProjectStatus } from "../../helpers/labels";

const sortProjects = (projects: any) => {
  const availableProjects = projects
    .filter((project: any) => project.status === ProjectStatus.FUNDING)
    .sort((a: any, b: any) => {
      // sort DSC by funded field
      return parseFloat(b.funded) - parseFloat(a.funded);
    });

  const ongoingProjects = projects
    .filter((project: any) => project.status === ProjectStatus.DEVELOPMENT)
    .sort((a: any, b: any) => {
      // sort DSC by funding_start field
      return (
        new Date(b.funding_start).getTime() -
        new Date(a.funding_start).getTime()
      );
    });

  const soonProjects = projects
    .filter((project: any) => project.status === ProjectStatus.DEPLOYED)
    .sort((a: any, b: any) => {
      // sort ASC by funding_start field
      return (
        new Date(a.funding_start).getTime() -
        new Date(b.funding_start).getTime()
      );
    });

  const finishedProjects = projects.filter(
    (project: any) => project.status === ProjectStatus.COMPLETED
  );

  return availableProjects
    .concat(ongoingProjects)
    .concat(soonProjects)
    .concat(finishedProjects);
};

const ProjectsSlider = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/projects`).then((res) => {
      const projects = sortProjects(res.data.projects);
      setProjects(sliderItems ? projects.slice(0, sliderItems) : projects);
    });
  }, []);

  const owlResponsive = {
    0: {
      items: 1,
    },
    991: {
      items: 3,
    },
  };

  if (projects && projects.length > 0)
    return (
      <OwlCarousel
        className="owl-theme projects-slider"
        dots
        responsive={owlResponsive}
        margin={20}
        mouseDrag={projects.length > 3}
        touchDrag={projects.length > 3}
      >
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            name={`project-${index + 1}`}
            data={project}
          />
        ))}
      </OwlCarousel>
    );

  return null;
};

export default ProjectsSlider;
